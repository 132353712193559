.product-page-firmware {
  .firmware-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 2rem;

    .firmware-list__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem 0;

      .firmware-left {
        display: flex;
        align-items: center;

        svg {
          width: 1rem;
          fill: var(--blue);
        }

        h4 {
          margin-left: 1rem;
        }
      }
    }
  }
}
