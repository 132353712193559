@font-face {
    font-family: 'Unica77 LL';
    src: url('Unica77LL-Bold.eot');
    src: url('Unica77LL-Bold.eot?#iefix') format('embedded-opentype'),
        url('Unica77LL-Bold.woff2') format('woff2'),
        url('Unica77LL-Bold.woff') format('woff'),
        url('Unica77LL-Bold.ttf') format('truetype'),
        url('Unica77LL-Bold.svg#Unica77LL-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Unica77 LL';
    src: url('Unica77LL-Light.eot');
    src: url('Unica77LL-Light.eot?#iefix') format('embedded-opentype'),
        url('Unica77LL-Light.woff2') format('woff2'),
        url('Unica77LL-Light.woff') format('woff'),
        url('Unica77LL-Light.ttf') format('truetype'),
        url('Unica77LL-Light.svg#Unica77LL-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Unica77 LL';
    src: url('Unica77LL-LightItalic.eot');
    src: url('Unica77LL-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('Unica77LL-LightItalic.woff2') format('woff2'),
        url('Unica77LL-LightItalic.woff') format('woff'),
        url('Unica77LL-LightItalic.ttf') format('truetype'),
        url('Unica77LL-LightItalic.svg#Unica77LL-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Monument Extended';
    src: url('MonumentExtended-Regular.eot');
    src: url('MonumentExtended-Regular.eot?#iefix') format('embedded-opentype'),
        url('MonumentExtended-Regular.woff2') format('woff2'),
        url('MonumentExtended-Regular.woff') format('woff'),
        url('MonumentExtended-Regular.ttf') format('truetype'),
        url('MonumentExtended-Regular.svg#MonumentExtended-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Unica77 LL';
    src: url('Unica77LL-Regular.eot');
    src: url('Unica77LL-Regular.eot?#iefix') format('embedded-opentype'),
        url('Unica77LL-Regular.woff2') format('woff2'),
        url('Unica77LL-Regular.woff') format('woff'),
        url('Unica77LL-Regular.ttf') format('truetype'),
        url('Unica77LL-Regular.svg#Unica77LL-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

