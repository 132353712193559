.dpv__intro {
  .intro-dpv {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 2rem;

    .drag {
      position: absolute;
      top: 50%;
      left: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: translate(-50%, -50%);
      border: 2px solid #1267dc;
      border-radius: 50%;
      width: 5rem;
      height: 5rem;
      pointer-events: none;
      box-shadow: 0px 0px 1px 1px #0000001a;
      h4 {
        color: #fff;
        font-weight: 500;
        margin-bottom: 0;
        text-shadow: 1px 1px 0px #000;
      }
    }

    .pulse {
      animation: pulse-animation 2s infinite;
    }

    @keyframes pulse-animation {
      0% {
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
      }
      100% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
      }
    }
    .intro-dpv__img {
      // background: #fff;
      position: relative;
      transform: scale(1.2);
      aspect-ratio: 0.9;
      cursor: grab;
      &.scale {
        transform: scale(1.25);
        aspect-ratio: 1;
      }
      &.scale-more-less {
        transform: scale(1.3);
        aspect-ratio: 1;
      }
      &.scale-more {
        transform: scale(1.7);
        aspect-ratio: 1;
      }
      &:active {
        cursor: grabbing;
      }
      &:active + .drag {
        opacity: 0.1;
      }

      // margin: auto;

      img {
        height: 100%;
        margin: 0 auto;
        object-fit: contain;
      }
    }
    .feature-icon {
      width: 32px;
      height: 32px;
      border: 1px solid #c3c3c3;
      cursor: pointer;
    }
    .intro-dpv__detail {
      display: flex;
      flex-direction: column;
      justify-content: center;
      // justify-content: space-between;

      .detail-dpv__code {
        padding: 0.5rem;
        background: var(--blue);
        display: inline-block;
        color: #fff;
        margin-top: 1rem;
      }

      .detail-dpv__description {
        margin-top: 1rem;
        font-size: 1.2rem;
        line-height: 1.5;
      }

      .detail-dpv__certifications {
        margin-top: 1rem;
        margin-bottom: 1rem;
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
