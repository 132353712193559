.select-language {
  cursor: default;
  position: relative;
  margin-left: 2rem;
  border-radius: 50%;

  .placeholder {
    width: 3rem;
    aspect-ratio: 1;
  }

  .options-container {
    position: absolute;
    top: calc(100% + 1rem);
    left: 0;
    display: flex;
    flex-direction: column;

    & > * + * {
      margin-top: 0.5rem;
    }

    .option {
      width: 3rem;
      aspect-ratio: 1;
      padding: 0;
    }
  }
}
