.portal-page {
  height: 100vh;
  display: flex;

  .section-content {
    position: relative;
    background: var(--grey);
    overflow-y: auto;
    flex-grow: 1;
    padding-top: 4rem;

    .heading-portal {
      padding-bottom: 2rem;
    }

    .subsection-pad {
      padding-top: 1rem;
      padding-bottom: 2rem;
    }

    .dpv-section {
      margin-bottom: 6rem;
    }

    .sort-select-options {
      display: flex;
      margin-bottom: 1rem;

      & > * + * {
        margin-left: 2rem;
      }
    }

    .category-course {
      margin-top: 0.5rem;
      text-transform: uppercase;
      font-size: 0.8rem;
      font-weight: 700;
    }
  }
  .layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #00000050;
    opacity: 1;
    z-index: 9998;
  }

  .warning-popup {
    opacity: 1;
    position: absolute;
    width: 30%;
    height: 120px;
    bottom: 0%;
    right: 0%;
    // transform: translate(-50%, -50%);
    z-index: 9999;
    background: #fff;
    padding: 1rem;
    border-top-left-radius: 1rem;
    font-size: 1rem;
    font-weight: 700;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: 0.9rem;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    animation: slide-from-bottom 0.7s;
  }

  .close-icon {
    position: absolute;
    width: 12px;
    height: 12px;
    top: 0.7rem;
    right: 0.7rem;
    cursor: pointer;
    z-index: 9999;
  }
}

@keyframes slide-from-bottom {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
