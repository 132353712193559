.request-access-popup {
  width: 90%;
  display: flex;
  flex-direction: column;
  text-align: center;

  & > * + * {
    margin-bottom: 2rem;
  }

  .request-access__form {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 2rem;
    row-gap: 1rem;
  }
  .error {
    color: rgb(255, 0, 0);
    font-weight: 500;
    margin-bottom: 0.5rem;
  }
}
