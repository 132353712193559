.dpd-single-page {
  // display: flex;
  // flex-direction: column;
  // height: 100%;
  // overflow-y: auto;

  .product-list-component__list {
    // height: unset;
    .list {
      width: 100%;
    }
  }
}
