.product-page-accessories {
  .accessories-list {
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    gap: 2rem;

    .accessories-list__item {
      border: 1px solid #bcbcbc;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      .image-container {
        height: 150px;
        display: flex;
        align-items: center;
      }
      img {
        margin: 0 auto;
        margin-bottom: 1rem;
      }
    }
  }
}
