// @import-normalize;
@import "./reset.css";
@import "./font/stylesheet.css";
@import "./variables";
@import "./layout";
@import "./typography";
@import "./buttons";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  scrollbar-width: thin;
  scrollbar-color: var(--darkGrey) #f0f0f0;

  &::-webkit-scrollbar {
    width: 0.5rem;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--darkGrey);
  }

  &::-webkit-scrollbar-track {
    background: #f0f0f0;
  }
}

body {
  font-weight: 300;
  font-family: var(--font1), Helvetica, Arial, sans-serif;
  color: var(--black);
}

img {
  display: block;
  max-width: 100%;
  margin: 0 auto;
  user-select: none;
}
.title-shadow {
  text-shadow: 1px 1px #fff;
}
