:root {
  font-size: 14px;

  --font1: "Unica77 LL";
  --font2: "Monument Extended";

  --black: #1f1f1f;
  --blackLight: rgba(0, 0, 0, 0.5);
  --blue: #1267dc;
  --blueLight: #4d86d4;
  --grey: #e2e2e2;
  --darkGrey: #4c4c4c;

  --blackBorder: rgba(0, 0, 0, 0.2);
  --lightBorder: rgba(255, 255, 255, 0.2);
}
