.admin-download-pagination {
  min-height: 300px;
  max-height: 300px;
  overflow-y: scroll;
}
.history-list {
  width: 100%;
  max-height: 445px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
}
.history-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 10px 0;
  border-bottom: 1px solid #e8e8e8;

  .history-row__left {
    display: flex;
    align-items: center;
    gap: 5px;

    &__name {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 16px;
      font-weight: 600;
    }
    &__email {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 18px;
      text-transform: lowercase;
    }
  }
  .history-row__right {
    display: flex;
    align-items: center;
  }
}
