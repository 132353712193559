.dpv__specs {
  .dpv-specs-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 2rem;
    row-gap: 1rem;

    .specs-list-item {
      display: flex;
      justify-content: space-between;
      padding: 1rem 0;

      .spec-type {
      }
    }
  }
}
