.container {
  max-width: 1100px;
  margin: 0 auto;

  @media screen and (max-width: 1500px) {
    max-width: 900px;
  }

  @media screen and (max-width: 1200px) {
    max-width: 800px;
  }
}

.title-gap {
  margin: 0 auto;

  @media screen and (max-width: 1500px) {
    padding-top: 80px;
  }

  @media screen and (max-width: 1200px) {
    padding-top: 80px;
  }
}

.account-container {
  max-width: 1600px;
  margin: 0 auto;

  @media screen and (max-width: 1500px) {
    max-width: 1150px;
  }

  @media screen and (max-width: 1200px) {
    max-width: 1000px;
  }
}

.bb-dark {
  border-bottom: 1px solid var(--blackBorder);
}

.bb-light {
  border-bottom: 1px solid var(--lightBorder);
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}
.justify-end {
  justify-content: flex-end;
}
.align-center {
  align-items: center;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.mb-4 {
  margin-bottom: 4rem;
}

.mb-6 {
  margin-bottom: 6rem;
}
