.comparison-page {
  display: flex;
  flex-direction: column;
  height: 100%;

  .comparison-page__list-wrapper {
    border-top: 1px solid #c3c3c3;
    flex-grow: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    .comparison-three-page-list {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      .image-container {
        height: 200px;
        img {
          max-height: 180px;
          margin: 0 auto;
        }
      }
    }
    .comparison-two-page-list {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      .image-container {
        height: 300px;
        img {
          max-height: 280px;
          margin: 0 auto;
        }
      }
    }

    .comparison-three-page-list,
    .comparison-two-page-list {
      flex-grow: 1;
      width: 90%;
      display: grid;
      gap: 2rem;
      .head-section {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-bottom: 2rem;
        padding-top: 2rem;
      }
      .item-comparison {
        .image-container {
          display: flex;
          align-items: center;
        }
        img {
          // height: 200px;
          margin: 0 auto;
        }

        &__section {
          border-top: 1px solid #c3c3c3;
          padding-top: 2rem;
          margin-bottom: 2rem;
          & .__specs {
            max-height: 82px;
          }
        }
      }
    }
  }
}
