.popup-container {
  display: flex;
  background-color: #00000050;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh !important;
  width: 100vw !important;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  .popup {
    position: relative;
    display: flex;
    flex-direction: column;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    align-items: center;
    //     overflow: scroll;
    width: 35%;
    justify-content: center;
    background-color: #fff;
    border-radius: 16px;
    padding: 32px;
    min-height: 0px;
    max-height: 75vh;

    @media (max-width: 1580px) {
      width: 45%;
    }

    @media (max-width: 1024px) {
      width: 70%;
    }
    @media (max-width: 768px) {
      padding: 24px;
      width: 90%;
    }
    @media (max-width: 564px) {
      padding: 24px 24px;
      width: 90%;
    }
    &.request-popup {
      width: 50%;
      max-height: 90vh;
      background-color: var(--grey);
      padding: 32px 0 0 0;
    }
    &.big {
      width: 42%;
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }
  .popup::-webkit-scrollbar {
    display: none;
  }
  .close-icon {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    cursor: pointer;
    font-size: 1.5rem;
    //     color: var(--grey);
    &:hover {
      color: var(--blue);
    }
  }
}
