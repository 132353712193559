.select-component {
  cursor: pointer;
  position: relative;
  border-radius: 50%;
  text-transform: uppercase;
  font-family: var(--font2);
  font-size: 0.7rem;
  min-width: 200px;

  &.normal-font {
    font-family: var(--font1);
    font-weight: 600;
    font-size: 0.9rem;
    letter-spacing: 2px;
    color: var(--black);
  }

  .placeholder {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.8rem 0;

    span {
      margin-right: 1rem;
    }

    svg {
      width: 0.8rem;
    }
  }

  .options-container {
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 0;
    width: 100%;
    box-shadow: 0 10px 10px rgba(#000, 0.5);
    overflow-y: auto;

    .option {
      background: var(--grey);
      text-align: center;
      padding: 0.7rem 0;

      &:hover {
        filter: brightness(90%);
      }
    }
  }
}
