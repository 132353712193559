.single-digit-input {
  padding: 12px 24px;
  font-size: 16px;
  width: 80px;
  margin: 12px 4px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: #222;
  background-color: #f5f5f3;
  border: 2px #f5f5f3 solid;
  border-radius: 50px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-family: "Poppins", sans-serif;
  &:disabled {
    display: none;
  }
  @media (max-width: 564px) {
    width: 48px;
    height: 48px;
    padding: 0;
  }
}
