.back-container {
  padding-bottom: 4rem;
  position: relative;
  .go-back-button {
    z-index: 99999999999;
    // background-color: red;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    cursor: pointer;

    &:hover {
      color: var(--blue);

      svg {
        fill: var(--blue);
      }
    }

    span {
      margin-left: 1rem;
      margin-top: 3px;
      font-size: 12px;
      font-weight: 600;
    }
  }
}
