.welcome-page {
  height: 100vh;
  background-image: url("/assets/suex-bg.png");
  background-size: cover;
  background-position-y: 50%;

  &__play-popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;

    svg {
      fill: #fff;
      width: 5rem;
      height: 5rem;
      margin-bottom: 1rem;
      padding: 2rem;
      border: 1px solid rgba(255, 255, 255, 0.4);
      border-radius: 50%;
    }

    span {
      color: #fff;
      text-transform: uppercase;
      font-size: 0.9rem;
    }
  }

  h1 {
    position: absolute;
    bottom: 2rem;
    left: 2rem;
    color: #fff;
    font-size: 6rem;
  }
}
