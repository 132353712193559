.product-page-documents {
  .documents-list {
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    gap: 2rem;

    .documents-list__item {
      display: flex;
      flex-direction: column;
      margin-bottom: 3rem;

      & > * + * {
        margin-top: 1rem;
      }

      .document-preview-icon {
        background: #fff;
        aspect-ratio: 1;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          width: 5rem;
          height: 5rem;
          fill: var(--blue);
          filter: opacity(50%);
        }

        .btn {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}
