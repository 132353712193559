.sidebar {
  background: var(--black);
  width: 250px;
  flex-shrink: 0;
  color: #fff;
  display: flex;
  flex-direction: column;
  border-right: 1px solid var(--grey);

  &__section {
    padding: 1.5rem 2rem;

    &.user-section {
      text-transform: uppercase;

      h5 {
        margin-bottom: 1rem;
        color: #fff;
      }

      .user-info {
        display: flex;

        svg {
          fill: rgba(#fff, 0.5);
          width: 1rem;
        }

        &__detail {
          margin-left: 1rem;

          .info-name {
            text-transform: uppercase;
            margin-bottom: 0.5rem;
          }

          .info-level {
            font-size: 0.8rem;
            color: rgba(#fff, 0.5);
          }
        }
      }
    }

    &.menu-section {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-grow: 1;
      padding: 1rem 2rem;
      overflow: auto;

      .menu-section__sections,
      .menu-section__account {
        display: flex;
        flex-direction: column;

        & > * + * {
          margin-top: 0.7rem;
        }
      }
      .menu-section__account {
      }
    }
  }

  &.trademark-section {
    text-align: center;
    color: rgba(#fff, 0.5);
    font-size: 0.9rem;
    padding: 1rem 2rem;
  }
}
