.watch-in-action__container {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  //   background-color: aqua;
}
.watch-in-action__video {
  width: calc(100vw - 300px);
  // padding: 0 2.5rem;
}
.watch-in-action__title {
  //   width: 100%;
  font-size: 2.5rem;
  position: absolute;
  top: 0;
  left: 10%;
  transform: translateY(-50%);
  text-shadow: 1px 1px #fff;

  //   background-color: aqua;
}
