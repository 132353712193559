.account-courses-single {
  .account-courses-wrapper {
    display: flex;
    flex-direction: column;

    & > * + * {
      margin-top: 2rem;
    }

    .account-courses-intro {
      display: flex;
      flex-direction: column;
      margin-bottom: 2rem;
      span {
        margin-bottom: 1.5rem;
      }

      img {
        width: 100%;
        height: 400px;
      }

      .course-description {
        font-size: 1.5rem;
        padding-top: 1.5rem;
      }
    }
  }
}
