.spinner-component {
  display: flex;
  justify-content: center;
  align-items: center;
  // padding-top: 1rem;
  // padding-bottom: 1rem;
  flex: 1;

  .spinner {
    border: 3px solid #fff;
    border-top: 3px solid var(--blue);
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;

    &.darker {
      border-color: var(--greyDarkColor);
      border-top-color: var(--blue);
    }

    &.lighter {
      border-color: #fff;
      border-top-color: var(--blue);
    }

    @media screen and (max-width: 768px) {
      width: 30px;
      height: 30px;
      border-width: 6px;
    }
  }

  .popup-spinner {
    border: 4px solid #fff;
    border-top: 4px solid var(--blue);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;

    &.darker {
      border-color: var(--greyDarkColor);
      border-top-color: var(--blue);
    }

    &.lighter {
      border-color: #fff;
      border-top-color: var(--blue);
    }

    @media screen and (max-width: 768px) {
      width: 30px;
      height: 30px;
      border-width: 6px;
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
