.product-page-video {
  .video-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 2rem;

    .video-list__item {
      display: flex;
      flex-direction: column;

      & > * + * {
        margin-top: 1rem;
      }
    }
  }
  .search-container {
    position: relative;

    .search-input {
      width: 400px;
      padding: 10px 40px 10px 10px;
      border: 1px solid gray;
      border-radius: 5px;
      outline: none;
      font-size: 14px;

      &:focus {
        background-color: #fff;
      }
    }

    .search-icon {
      position: absolute;
      max-height: 15px;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      font-size: 20px;
      color: gray;
    }
  }
}
