.perm-denied {
  font-size: 2rem;
  font-weight: 500;
  height: calc(100vh - 4rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    font-size: 1.5rem;
    font-weight: 300;
    padding-top: 8px;
  }
}
