div.btn,
a.btn,
button.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-family: var(--font2);
  font-weight: 400;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  font-size: 0.7rem;
  border-radius: 25px;
  text-align: center;
  cursor: pointer;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &.left {
    align-self: flex-start;
  }

  &.center {
    align-self: center;
  }

  &.right {
    justify-self: flex-end;
    align-self: flex-end;
  }

  &.space {
    margin-left: 1.5rem;

    &:first-child {
      margin-left: 0;
    }
  }

  &.secondary {
    background: transparent;
    &.danger {
      color: crimson;
      &:hover {
        color: #fff;
        background: crimson;
      }
    }

    &.dark {
      border: 1px solid rgba(#000, 0.2);
      color: var(--black);

      &:hover {
        color: #000;
      }

      svg {
        fill: #fff;
      }
    }

    &.light {
      border: 1px solid rgba(#fff, 0.2);
      color: rgba(#fff, 0.8);

      &:hover {
        color: #fff;
        background-color: var(--darkGrey);
        border: 1px solid var(--darkGrey);
      }
    }
  }

  &.tertiary {
    color: var(--darkGrey);
    background: #fff;

    &:hover {
      color: #fff;
      background: var(--darkGrey);

      svg {
        fill: #fff;
      }
    }
    &.active {
      color: #fff;
      background: var(--darkGrey);
      svg {
        fill: #fff;
      }
      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }

    svg {
      fill: var(--darkGrey);
    }
  }

  &.primary {
    color: #fff !important;
    background: var(--darkGrey);
    border-color: var(--darkGrey) !important;

    &.hover:hover {
      filter: saturate(50%);
    }
  }

  &.pad {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  &.short-pad {
    padding: 0.5rem 1rem;

    svg {
      width: 1rem;
      margin-right: 0.5rem;
    }
  }
}
