a {
  text-decoration: none;
  color: var(--black);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  text-transform: uppercase;
  font-family: var(--font2);
  margin: 0;
  color: var(--black);
}

h1 {
  font-size: 3rem;
  line-height: 1;

  &.border {
    border-bottom: 1px solid var(--blackBorder);
    padding-bottom: 2rem;
    margin-bottom: 1.5rem;
  }
}

h2 {
  font-size: 1.8rem;
  margin-bottom: 2.5rem;

  &.no-mar {
    margin: 0;
  }
}

h3 {
  font-size: 1.2rem;
  margin-bottom: 1.3rem;

  &.no-mar {
    margin: 0;
  }
}

h4 {
  font-size: 0.9rem;
  margin-bottom: 1.6rem;

  &.no-mar {
    margin: 0;
  }
}

h5 {
  font-size: 1rem;
  margin-bottom: 1.5rem;

  &.no-mar {
    margin: 0;
  }
}

p {
  line-height: 1.2;
  font-size: 1.1rem;
}

span.text-dark {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  font-size: 0.9rem;
  margin-bottom: 0.4rem;
  color: var(--blackLight);
}

input {
  color: var(--black);
  font-family: var(--font1);
  background: var(--grey);
  border: none;
  border-bottom: 1px solid var(--blackBorder);
  outline: none !important;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 2px;
  appearance: none;
  padding: 1rem 0;

  &::placeholder {
    font-size: 0.9rem;
    opacity: 1;
    font-weight: 700;
    color: var(--black);
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

input[type="number"] {
  -moz-appearance: textfield;
}

.checkbox-form {
  display: flex;
  align-items: center;

  input[type="checkbox"] {
    cursor: pointer;
    width: 1.5rem;
    height: 1.5rem;
    border: 1.5px solid var(--blackBorder);
    position: relative;
    padding: 0.5rem;

    &:checked {
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 70%;
        height: 70%;
        background: var(--blue);
        transform: translate(-50%, -50%);
      }
    }
  }

  label {
    color: var(--black);
    font-size: 0.9rem;
    cursor: pointer;
    margin-left: 1.2rem;
  }
}
