.help-page {
  .help-page__content {
    .help-content-wrapper {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));

      .help__contacts {
        .mini-section {
          width: 55%;
          display: flex;
          flex-direction: column;

          .mini-section__list {
            display: grid;
            gap: 2rem;
            padding: 2rem 0;
            margin-bottom: 2rem;

            .item-contact {
              display: grid;
              grid-auto-flow: column;
              justify-content: start;
              gap: 1rem;

              svg {
                fill: var(--blue);
              }

              .text {
                line-height: 1.5;
                font-size: 1.2rem;
              }
            }
          }

          p {
            font-size: 1.1rem;
            line-height: 1.2;
          }
        }
      }

      .help__forms {
        display: flex;
        flex-direction: column;

        & > * + * {
          margin-top: 2rem;
        }

        p {
          font-size: 1.6rem;
          line-height: 1.2;
        }

        .form-input {
          display: flex;
          flex-direction: column;
          margin-bottom: 1rem;

          & > * {
            margin-bottom: 1rem;
          }
        }

        .checkbox {
          display: flex;
          flex-direction: column;

          & > * + * {
            margin-top: 0.5rem;
          }
        }
      }
    }
  }

  .message-created {
    position: absolute;
    top: 20px;
    right: -260px; /* Posiziona il popup fuori dallo schermo a sinistra */
    width: 250px;
    height: 50px;
    border: 1px solid green;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    border-radius: 5px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
    z-index: 1000;
    animation: slideIn 1.5s ease-in-out forwards,
      slideOut 1.5s ease-in-out 3s forwards; /* Usa due animazioni separate */

    h3 {
      margin: 0;
    }
  }

  @keyframes slideIn {
    0% {
      right: -260px; /* Posizione iniziale fuori dallo schermo a sinistra */
    }
    100% {
      right: 40px; /* Posizione finale a right: 20px */
    }
  }

  @keyframes slideOut {
    0% {
      right: 40px; /* Posizione iniziale a right: 20px */
    }
    100% {
      right: -260px; /* Posizione finale fuori dallo schermo a sinistra */
    }
  }
}
