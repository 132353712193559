.product-list-component {
  .heading-portal {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .compare-cta {
      display: flex;
      align-items: center;

      &:hover {
        svg {
          background: var(--blue);
          fill: #fff;
        }
      }

      span {
        text-transform: uppercase;
        font-size: 0.9rem;
      }

      svg {
        margin-left: 1rem;
        fill: var(--blue);
        width: 2.2rem;
        height: 2.2rem;
        background: #fff;
        border-radius: 50%;
        padding: 0.4rem;
      }
    }
  }

  &__list {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    height: 0;

    .list {
      flex-grow: 1;
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      gap: 3rem;

      &__dpv {
        position: relative;
        border: 1px solid #bcbcbc;
        // background: #fff;

        // &:hover {
        //   filter: brightness(80%);
        // }
        .image-container {
          height: 230px;
          display: flex;
          align-items: center;
        }
        a {
          height: 100%;
          padding: 1.5rem;
          display: flex;
          flex-direction: column;
          img {
            margin: 0 auto;
            max-height: 230px;
          }
          img.bigger-img {
            margin: 0 auto;
            height: 200%;
            width: 200%;
            object-fit: cover;
          }
        }

        &:hover {
          .select-compare {
            display: block;
          }
        }

        &.active {
          .select-compare {
            display: block;
            fill: var(--blue);
          }
          filter: opacity(70%);
        }

        .select-compare {
          display: none;
          position: absolute;
          top: 1rem;
          right: 1rem;
          fill: var(--grey);
        }
      }
    }
  }
}
