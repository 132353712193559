.navigation-arrows-component {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-column-end: 6;

  & > * + * {
    margin-left: 0.5rem;
  }

  svg {
    transform: scale(0.8);
  }

  .arrow-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
    border: 1px solid transparent;
    border-radius: 50%;
    cursor: pointer;
    transition: border-color 0.2s ease-in-out;

    &:hover {
      border-color: var(--blue);
    }
  }
}
