.admin-panel {
  margin-bottom: 100px;
  .popup__details-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    &__label {
      text-transform: uppercase;
      font-weight: 500;
    }
    & > div {
      width: 50%;
      padding: 16px 4px;
    }
  }
  .popup__button-container {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-top: 24px;
  }
  &__title-container {
    margin-top: 2rem;
    display: flex;
    align-items: baseline;
    & > * {
      margin-right: 10px;
    }
    &__show-toggle {
      margin-right: 0;
      font-size: 0.9rem;
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        text-decoration: none;
      }
    }
    & > h3 {
      font-size: 1.5rem;
    }
    &__title-search-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      .searchbar-container {
        display: flex;
        align-items: center;
        width: 400px;
        position: relative;

        &__searchbar-input {
          width: 100%;
          padding: 10px 40px 10px 10px;
          border: 1px solid grey;
          border-radius: 5px;
          outline: none;
          font-size: 14px;
          &:focus {
            background-color: #fff;
          }
        }

        &__searchbar-input-active {
          width: 100%;
          padding: 5px 15px;
          border: 1px solid rgb(179, 179, 179);
          background-color: #f1f1f1;
          border-radius: 5px;
          outline: none;
          font-size: 14px;
          &:focus {
            background-color: #fff;
          }
        }

        &__searchbar-icon {
          position: absolute;
          max-height: 15px;
          top: 50%;
          right: 10px;
          transform: translate(-50%, -50%);
          color: gray;
        }
      }
    }
  }
  .row-container {
    min-height: 52px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.8rem;
    align-items: center;
  }
  .download-row,
  .user-row {
    background-color: #fff;
    //     border-radius: 1rem;
    // padding: 10px;
    margin-bottom: 10px;

    &.disabled {
      opacity: 0.5;
    }
    &.no-click {
      cursor: default;
      pointer-events: none;
    }

    .user-data-container {
      // width: 30%;
      // padding-top: 1rem;
      display: flex;
      flex-direction: column;
      max-height: 400px;
      overflow-y: auto;
      .course-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem;
        border-top: 1px solid #e5e5e5;
        // & > div {
        //   flex: 1;
        // }
      }
      //       border-top: 1px solid #e5e5e5;
      //       & > div {
      //         margin-bottom: 1rem;
      //       }
    }
  }
  .overflow-pagination {
    min-height: 300px;
    max-height: 300px;
    overflow-y: scroll;
  }
  .overflow-pagination-user {
    min-height: 480px;
    max-height: 480px;
    overflow-y: scroll;
  }
}

.edit-button {
  height: 25px;
  width: 25px;
  cursor: pointer;
  fill: rgb(51, 51, 51);
  display: flex;
  align-items: center;
  justify-content: center;
}
.history-button {
  height: 25px;
  width: 25px;
  cursor: pointer;
  fill: rgb(51, 51, 51);
  display: flex;
  align-items: center;
  justify-content: center;
}
.save-button {
  height: 30px;
  width: 30px;
  cursor: pointer;
  fill: rgb(51, 51, 51);
}
