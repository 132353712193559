.dpv__troubleshooting-faq {
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;

  .troubleshooting-list {
    .troubleshooting-list-wrapper {
      display: flex;
      flex-direction: column;

      & > * + * {
        margin-top: 1rem;
      }

      .troubleshooting-list__faq {
        .question {
          padding: 1.5rem 0;
          // text-transform: uppercase;
          // font-family: var(--font2);
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          opacity: 0.5;

          &.opacity {
            opacity: 1;
          }

          svg {
            flex-shrink: 0;
            fill: var(--blackBorder);
            width: 0.8rem;
            position: relative;
            margin-left: 1rem;
          }
        }

        .answer {
          padding: 2rem 0;
        }

        .answer-list {
          display: flex;
          flex-direction: column;

          & > * {
            margin-top: 2rem;
          }

          &__answer {
            display: flex;
            align-items: center;
            // padding: 20px;

            svg {
              flex-shrink: 0;
              fill: var(--blue);
              width: 1rem;
            }

            // .answer-text {
            //   margin-left: 1rem;
            // }
          }
        }
      }
    }
  }
}
