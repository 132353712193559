.account-info {
  .account-info__wrapper {
    width: 40%;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 1800px) {
      width: 40vw;
    }

    // }

    .info-section {
      padding: 2rem 0;
    }

    .account-info__info {
      display: flex;

      & > * + * {
        margin-left: 1.5rem;
      }

      .account-info {
        display: flex;
        flex-direction: column;

        & > * + * {
          margin-top: 1rem;
        }

        &__username {
          font-size: 1.5rem;
          text-transform: uppercase;
        }

        &__level {
          text-transform: uppercase;
          color: var(--blackBorder);
          font-weight: 600;
          letter-spacing: 3px;
          font-size: 0.9rem;
        }

        &__signup-from {
          font-size: 0.9rem;
        }
      }
    }

    .abilitations__list {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;

      & > * + * {
        margin-top: 0.2rem;
      }

      .abilitations-list__abilitation {
        display: flex;

        svg {
          fill: var(--blue);
          width: 1rem;
          flex-shrink: 0;
        }

        p {
          margin-left: 1rem;
          line-height: 1.5;
        }
      }
    }

    .dealer-info-list {
      display: flex;
      flex-direction: column;

      & > * + * {
        margin-top: 0.5rem;
      }

      &__item {
        display: flex;
        align-items: center;

        svg {
          fill: var(--blue);
          width: 0.9rem;
          flex-shrink: 0;
        }

        p {
          margin-left: 0.8rem;
        }
      }
    }
  }
}

.download-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: start;
  gap: 20px;
}
