.account-page {
  .account-page__nav-links {
    display: flex;
    margin-bottom: 2rem;

    & > * + * {
      margin-left: 2rem;
    }
  }
}
