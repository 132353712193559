.dpv__highlights {
  .dpv-highlights-block {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 2rem;

    img {
      max-height: 400px;
      // aspect-ratio: 1;
      margin: 0 auto;
      object-fit: cover;
      user-select: none;
    }

    .dpv-highlights-description {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      max-width: 550px;

      .dpv-highlights-top {
        display: flex;
        flex-direction: column;
        & > * {
          user-select: none;
        }

        & > * + * {
          margin-top: 1rem;
        }

        &__heading {
          // display: flex;
          // align-items: center;
          text-transform: uppercase;
        }
      }
    }
  }
}
