.welcome-page-navbar {
  height: 6rem;
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: var(--blackLight);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    &__popup {
      background: var(--grey);
      padding: 1.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 40%;
      max-width: 50%;

      svg {
        width: 1.1rem;
        align-self: flex-end;
        cursor: pointer;
        // margin-bottom: 1rem;
      }

      p {
        font-size: 1.6rem;
      }
    }
  }

  &__links {
    display: flex;

    .navbar-access {
      display: flex;
      align-items: center;

      svg {
        fill: #fff;
        width: 1rem;
      }
    }
  }
}
