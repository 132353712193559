.fullscreen-layer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
  .image-container {
    width: 70%;
    height: 98%;
    background-color: #fff;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .close {
    position: absolute;
    top: 2%;
    right: 2%;
    width: 30px;
    height: 30px;
    color: #c4c4c4;
    cursor: pointer;
  }
}
