.login-popup {
  width: 90%;
  display: flex;
  flex-direction: column;
  text-align: center;

  & > * + * {
    margin-bottom: 2rem;
  }

  .login__form {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    gap: 1rem;
  }
  .login-error {
    color: crimson;
    font-weight: 500;
  }
}
