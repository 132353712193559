.account-page__courses-section {
  height: 100%;
  width: 100%;
  .courses__list {
    height: 100%;
    width: 100%;
    .courses-list {
      height: 100%;
      width: 100%;
      .courses-list__category-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        width: 100%;
        .courses-list__course-category {
          display: flex;
          height: 100%;
          width: 100%;
          flex-wrap: wrap;
          justify-content: start;

          .courses-list__course {
            display: flex;
            flex-direction: column;
            height: 100%;
            width: calc(100% / 3);
            margin-bottom: 2rem;
            padding: 5px;
            h4 {
              margin-top: 1rem;
            }
          }
        }
      }
    }
  }
}
