.dpv__features {
  background-color: var(--black);
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 2rem 0;
  overflow-x: hidden;
  &__image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 100%;
    user-select: none;
    .image-left {
      position: absolute;
      // top: 50%;
      left: 0;
      // transform: translateY(-50%);
      transform: translateX(-90%);
      user-select: none;
    }
    .image-right {
      position: absolute;
      // top: 50%;
      right: 0;
      // transform: translateY(-50%);
      transform: translateX(90%);
      user-select: none;
    }
    img {
      -moz-user-select: none;
      -webkit-user-select: none;
      user-select: none;
      max-width: 880px;
      // aspect-ratio: 1;
      margin: 0 2.5rem;
      // object-fit: cover;
    }

    .arrow-container {
      width: 48px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border: 1px solid var(--blue);
      border-radius: 50%;

      svg {
        fill: #fff;
        transform: scale(0.8);
      }
    }
  }
  .dpv-features-block {
    .dpv-features-description {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-top: 2rem;
      &__title {
        color: #fff;
        font-size: 2.5rem;
        margin-top: 1rem;
      }
      & > * + * {
        margin-top: 1rem;
      }
      .active-feature {
        color: #aaa;
        font-size: 0.8rem;

        span {
          color: #fff;
          font-weight: 500;
          // font-size: 1.5rem;
        }
      }
    }
  }
}
